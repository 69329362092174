import { FormControl, FormLabel } from '@material-ui/core'
import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'
import RolesFilter from '../filters/RolesFilter'
import AreasFilter from '../filters/AreasFilter'

const UsersFilter = () => {
  return (
    <div className="flex flex-col md:flex-row p-4 gap-8">
      <RolesFilter label="Perfil" />
      <FormControl component="fieldset" classes={{ root: 'mt-6 md:mt-0' }}>
        <FormLabel component="legend" className="font-bold text-gray-800 text-base">
          Con acceso a
        </FormLabel>
        <CheckboxField name="allowed_access" label="Formularios" value="allowed_forms" margin="none" />
        <CheckboxField name="allowed_access" label="Consultas rápidas" value="allowed_quick_searches" margin="none" />
      </FormControl>
      <FormControl>
        <AreasFilter />
      </FormControl>
    </div>
  )
}

export default UsersFilter
