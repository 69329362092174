import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import BeforeunloadForm from './BeforeunloadForm'
import SaveButton from '../../molecules/buttons/SaveButton'
import ScrollToTop from '../../molecules/ScrollToTop'
import pageMutators, { markForDestroyPageMutator } from '../../../utils/pageMutators'
import { clearMutator, customMoveMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import { focusOnFirstError } from '../../../utils/decorators'
import { setupSerializedFormData } from '../../../configs/formsConfig'

const Wizard = ({
  stepPages,
  mutateForm,
  initialValues,
  buttonMessage = 'Guardar y continuar',
  step = 1,
  verifyBeforeUnload = false,
  noPristineToDisable = false,
  decoratorsList = []
}) => {
  const [uploadProgress, setUploadProgress] = useState(null)

  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    if (mutateForm) {
      await mutateForm({ values: formData, config })
    }
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        clear: clearMutator,
        swap: customSwapMutator,
        move: customMoveMutator,
        markForDestroy: markForDestroyMutator,
        markForDestroyPage: markForDestroyPageMutator,
        ...pageMutators
      }}
      decorators={[focusOnFirstError, ...decoratorsList]}
      subscription={{ pristine: true, submitting: true, submitSucceeded: true, values: true }}
    >
      {({ handleSubmit, pristine, submitting, submitSucceeded }) => (
        <form onSubmit={handleSubmit}>
          {verifyBeforeUnload && (
            <BeforeunloadForm pristine={pristine} submitting={submitting} submitSucceeded={submitSucceeded} />
          )}
          {stepPages[step]}
          <div className="mb-10 md:mb-12">
            <SaveButton
              label={buttonMessage}
              uploadProgress={uploadProgress}
              disabled={(!noPristineToDisable && pristine) || submitting}
            />
          </div>
          <ScrollToTop trigger={step} />
        </form>
      )}
    </Form>
  )
}

export default Wizard

Wizard.propTypes = {
  stepPages: PropTypes.array,
  mutateForm: PropTypes.func,
  initialValues: PropTypes.object,
  buttonMessage: PropTypes.string,
  step: PropTypes.number,
  verifyBeforeUnload: PropTypes.bool,
  noPristineToDisable: PropTypes.bool,
  decoratorsList: PropTypes.arrayOf(PropTypes.func)
}
