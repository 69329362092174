import React from 'react'
import PropTypes from 'prop-types'
import CheckboxesField from '../../molecules/fields/CheckboxesField'
import { FormControl } from '@material-ui/core'
import { answerStates } from '../../../configs/answerConfig'
import DateRangeField from '../../molecules/fields/DateRangeField'

const webHookStates = [
  { code: 'success', name: 'Enviadas' },
  { code: 'initial', name: 'No enviadas' },
  { code: 'failed', name: 'Envíos fallidos' },
  { code: 'send_with_error', name: 'Incompletas' }
]

const AnswersFilter = ({ formHasWebhook }) => (
  <div className="flex gap-10 md:flex-row w-full flex-col py-6 px-0.5">
    <FormControl component="fieldset" classes={{ root: 'mr-2 ml-1' }}>
      <CheckboxesField label="Estado" options={answerStates} name="status" />
    </FormControl>
    {formHasWebhook && (
      <FormControl component="fieldset" classes={{ root: 'mr-2 ml-1' }}>
        <CheckboxesField label="Enviadas a la API" options={webHookStates} name="webhook_status" />
      </FormControl>
    )}
    <FormControl component="fieldset">
      <DateRangeField name="date" label="Fecha de creación" />
    </FormControl>
  </div>
)

export default AnswersFilter

AnswersFilter.propTypes = {
  formHasWebhook: PropTypes.bool
}
