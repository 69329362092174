import useInvalidateQueries from './useInvalidateQueries'
import { urlToJson } from '../utils/helpers'
import useCrud from './useCrud'
import { useFileUploadMutation } from './useFileUploadMutation'

export const urlBaseForm = `/admin/v1/forms`
const urlBaseFormTest = `/admin/v1/form_test`

const useForm = id => {
  const { get: getForms, findById } = useCrud(urlBaseForm)

  const getForm = () => findById(['form', id])

  const { mutate: updateForm } = useFileUploadMutation(urlBaseFormTest, id)

  const { get: getAllFormIds } = useCrud(`${urlBaseForm}/all_forms_ids`)

  const { get: getAllForm } = useCrud(`${urlBaseForm}/all_forms`)

  const { create: createExport } = useCrud(`${urlBaseForm}/export`)

  const invalidateQueries = useInvalidateQueries()

  const invalidateForms = () => invalidateQueries([urlToJson(urlBaseForm)])

  const invalidateForm = () => invalidateQueries(['form', id])

  return {
    invalidateForms,
    getForms,
    invalidateForm,
    getForm,
    getAllFormIds,
    getAllForm,
    updateForm,
    createExport
  }
}
export default useForm
