import PropTypes from 'prop-types'
import React from 'react'
import * as validations from '../../../utils/validations'
import TextWithAlternativesField from './TextWithAlternativesField'
import AlternativeLimits from './AlternativeLimits'

const CheckboxesField = ({ baseName, label }) => {
  return (
    <TextWithAlternativesField
      hasAddOtherOption
      baseName={baseName}
      label={label}
      alternativeValidations={validations.requiredArray('Ingresa al menos una opción.', 1)}
      asideAlternative={
        <>
          <legend className="font-bold flex items-center">Alternativas</legend>
          <AlternativeLimits />
        </>
      }
    />
  )
}

export default CheckboxesField

CheckboxesField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
