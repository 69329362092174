import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import useInstitution from '../../../hooks/useInstitution'
import useUserPermission from '../../../hooks/useUserPermission'
import { useBranchOfficeFilter } from '../../../hooks/useBranchOfficeFilter'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import UserStatus from './UserStatus'
import UserTableAllowed from './UserTableAllowed'

const UsersTable = ({ users = [], onDestroy }) => {
  const { querySameInstitution } = useInstitution()
  const { canEdit, canDelete } = useUserPermission('user')
  const showBranchOfficeColumn = useBranchOfficeFilter(['all_branches'])
  const showActions = user => (
    <td className="text-right">
      {canEdit(user.institution_id) && <EditButton to={`/usuarios/${user.id}/editar`} />}
      {canDelete(user.institution_id, user.id) && <DeleteButton onClick={() => onDestroy(user)} />}
    </td>
  )

  return (
    <div className="overflow-x-scroll md:overflow-x-auto pt-8 pb-2">
      <Table
        spacing="normal"
        className="table-fixed md:w-full w-800 md:w-auto"
        values={users}
        head={
          <Tr body={false}>
            <Th className="w-3/12">Nombre</Th>
            <Th className="w-2/12">DNI</Th>
            <Th className="w-2/12">Perfil</Th>
            <Th className="w-3/12">Accesos</Th>
            {showBranchOfficeColumn && <Th className="w-3/12">Sede</Th>}
            <Th className="w-3/12">Área</Th>
            <Th className="w-2/12">Estado</Th>
            {querySameInstitution && <Th className="w-2/12 text-right">Acciones</Th>}
          </Tr>
        }
        body={users.map(user => {
          return (
            <Tr key={user.id}>
              <Td>
                <Link to={`/usuarios/${user.id}/detalles`} className="text-lg">
                  {user.name}
                </Link>
              </Td>
              <Td className="text-lg">{user.document_number}</Td>
              <Td className="text-lg">{user.role_name}</Td>
              <Td className="text-lg">
                {user.role_name !== 'Colaborador' ? 'Todos los servicios' : <UserTableAllowed user={user} />}
              </Td>
              {showBranchOfficeColumn && <Td className="text-lg">{user.branch_office_name}</Td>}
              <Td className="text-lg">{user.area_name}</Td>
              <Td className="text-lg">
                <UserStatus user={user} />
              </Td>
              {querySameInstitution && showActions(user)}
            </Tr>
          )
        })}
      />
    </div>
  )
}

export default UsersTable

UsersTable.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  users: PropTypes.array
}
