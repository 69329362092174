import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import IconFilter from '../../../images/icons/filter.svg'
import { ReactSVG as Svg } from 'react-svg'
import clsx from 'clsx'
import useSearchParams from '../../../hooks/useSearchParams'
import { makeStyles } from '@material-ui/core'
import useFilters from '../../../hooks/useFilters'

const useStyles = makeStyles(() => ({
  expanded: {
    marginTop: '0px !important'
  }
}))

const AccordionFilter = ({
  children,
  accordionHasBackground = true,
  hasHeader,
  displayExpandedFilter = false,
  keepOnClear = ['institution_id', 'branch_office_id']
}) => {
  const searchParams = useSearchParams()
  const expanded = Boolean(searchParams.get('filter_open')) || displayExpandedFilter
  const [accordionExpanded, setAccordionExpanded] = useState(expanded)
  const { clearUrlFilters } = useFilters()
  const { reset, getState } = useForm()

  const backgroundColor = hasHeader
    ? 'bg-neutral-200'
    : (accordionHasBackground || accordionExpanded) && 'bg-neutral-100'

  const handleChange = (_, state) => setAccordionExpanded(state)

  const handleClearFilter = () => {
    const formValues = getState().values
    const newInitialValues = keepOnClear.reduce((acc, key) => {
      acc[key] = formValues[key]
      return acc
    }, {})
    clearUrlFilters({ keepParams: keepOnClear })
    reset(newInitialValues)
  }

  const classes = useStyles()
  return (
    <Accordion
      classes={{ root: 'shadow-none', expanded: hasHeader && classes.expanded }}
      expanded={accordionExpanded}
      onChange={handleChange}
      data-testid="searchFilter"
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: clsx('md:px-6 px-5 h-16', (accordionExpanded || hasHeader) && backgroundColor),
          content: 'items-center justify-start md:justify-end'
        }}
      >
        <p className="text-blue-700 font-bold flex">Filtros de búsqueda</p>
        <Svg src={IconFilter} className="ml-2 text-blue-700 font-bold flex" />
      </AccordionSummary>

      <AccordionDetails
        classes={{ root: clsx('pt-0 md:px-6 px-5 border-t border-solid border-gray-350', backgroundColor) }}
      >
        <div className="flex flex-col-reverse md:flex-row justify-between w-full">
          {children}
          <button
            type="button"
            className="text-base text-blue-700 min-w-45 py-2 mt-1 text-left md:text-center self-start"
            onClick={handleClearFilter}
          >
            Borrar filtros
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionFilter

AccordionFilter.propTypes = {
  accordionHasBackground: PropTypes.bool,
  children: PropTypes.node,
  hasHeader: PropTypes.bool,
  displayExpandedFilter: PropTypes.bool,
  keepOnClear: PropTypes.array
}
