import React from 'react'
import PropTypes from 'prop-types'
import Value from '../../../../molecules/Value'
import InfoAutoResponseFromTemplate from '../../InfoAutoResponseFromTemplate'
import { HtmlField } from '../../../../molecules/fields/HtmlField'
import * as validations from '../../../../../utils/validations'
import formValidations from '../../../../../utils/formValidations'
import useFieldValue from '../../../../../hooks/useFieldValue'
import CheckboxField from '../../../../molecules/fields/CheckboxField'

const AutoResponse = ({ config }) => {
  const templateId = useFieldValue('template_id')
  const defaultQuestionsValue = useFieldValue('default_questions')
  const showInfoAutoResponseFromTemplate = config.auto_response === 'official_decide' && templateId
  return (
    <>
      <CheckboxField name="auto_response" margin="none" label="Establecer respuesta automática." />
      {showInfoAutoResponseFromTemplate && <InfoAutoResponseFromTemplate />}
      <Value name="auto_response" key={defaultQuestionsValue.toString()}>
        {({ value: autoResponse }) =>
          autoResponse && (
            <HtmlField
              name="auto_response_text"
              label="Texto de la respuesta"
              hasMaxContentLength={false}
              allowScrolling
              className="ml-7"
              editorWrapperClassName="sm:max-w-xl"
              validate={validations.mix(
                validations.required(),
                formValidations.validateAutoResponseDefaultQuestion(defaultQuestionsValue)
              )}
            />
          )
        }
      </Value>
    </>
  )
}

export default AutoResponse

AutoResponse.propTypes = {
  config: PropTypes.object
}
