import React, { Fragment } from 'react'
import Container from '../../../atoms/Container'
import AnswersTable from '../../../organisms/answers/AnswersTable'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from 'react-query'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import AnswersFilter from '../../../organisms/answers/AnswersFilter'
import useFilters from '../../../../hooks/useFilters'
import ExportButton from '../../../organisms/exports/ExportButton'
import { BoxTable } from '../../../molecules/BoxTable'
import PageHeading from '../../../molecules/PageHeading'

const fetchForm = id => axios.get(`/admin/v1/forms/${id}.json`).then(a => a.data)

const Answers = () => {
  const { id: formId } = useParams()
  const url = `/formularios/${formId}/exportaciones`
  const {
    onFilter,
    filters,
    initialFilters,
    results: answers
  } = useFilters(`/admin/v1/forms/${formId}/answers.json`, { sort: 'desc' })
  const { data: formData, status: formStatus } = useQuery(['formulario', formId], () => fetchForm(formId))

  if (formStatus !== 'success') return null

  return (
    <Fragment>
      <PageNavigation breadcrumbData={formData} />
      <Container>
        <PageHeading
          title={`Respuestas de ${formData.name}`}
          pageDescription="Revisa todas las respuestas que tu formulario ha recibido."
        />
        <BoxTable
          filters={<AnswersFilter formHasWebhook={formData.has_webhook} />}
          onFilter={onFilter}
          initialFilters={initialFilters}
          values={answers}
          displayTotal
          displaySort
          displayHeader={false}
          accordionHasBackground={false}
          searchPlaceholder="Buscar por nombre, nro. de documento o código"
          searchClassName="sm:max-w-104"
          asideFilter={
            <ExportButton
              title={`Exportar respuestas`}
              url={url}
              filters={filters}
              exportable={{ type: 'form', id: formId }}
            />
          }
        >
          <AnswersTable form={formData} answers={answers.data} formHasCost={answers.form_has_cost} />
        </BoxTable>
      </Container>
    </Fragment>
  )
}

export default Answers
