import PropTypes from 'prop-types'
import React from 'react'
import { webhookStatusMap } from '../../hooks/useAnswerToWebhook'
import InfoTooltip from './InfoTooltip'
import CircularProgress from '../atoms/icons/CircularProgress'

const WebhookStatusLabel = ({ id, status, infoContent }) => {
  if (status === 'sending') return <CircularProgress id={id} className="m-auto" ariaLabel="Enviando" />

  return (
    <>
      {webhookStatusMap[status]}
      {status === 'send_with_error' && (
        <InfoTooltip
          iconClassName="text-red-700"
          leaveTouchDelay={10000}
          tooltipText={<p className="w-52 font-normal">{infoContent}</p>}
        />
      )}
    </>
  )
}

export default WebhookStatusLabel

WebhookStatusLabel.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  infoContent: PropTypes.node
}
