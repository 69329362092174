import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../atoms/Button'
import Dialog from '../../molecules/dialogs/Dialog'
import { useAuth } from '../../molecules/Auth'
import useForm from '../../../hooks/useForm'

const ExportFormList = ({ filters }) => {
  const [popupInfo, setPopupInfo] = useState(false)
  const { user } = useAuth()
  const { createExport } = useForm()

  const handleExport = async e => {
    setPopupInfo(true)
    createExport(filters)
  }

  const handleClose = () => {
    setPopupInfo(false)
  }

  return (
    <div>
      <Button
        className="flex mb-4 md:mb-2 w-full md:max-w-64"
        size="full"
        onClick={handleExport}
        variant="secondary"
        disabled={false}
      >
        Exportar formularios
      </Button>
      {popupInfo && (
        <Dialog
          maxWidth="sm"
          buttonClose={false}
          renderTitle={<p className="header font-bold text-lg">Exportando formularios</p>}
          renderContent={
            <p className="border-neutral-300 pt-6">
              Cuando finalice el proceso, recibirás la información como un archivo de Excel en el correo
              <span className="font-bold"> {user.email}.</span>
            </p>
          }
          renderActions={
            <Button size="full" className="md:w-40 w-full" onClick={handleClose}>
              Ok
            </Button>
          }
          onClose={handleClose}
        />
      )}
    </div>
  )
}

export default ExportFormList

ExportFormList.propTypes = {
  filters: PropTypes.string
}
