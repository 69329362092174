import React, { useState } from 'react'
import PageHeading from '../../../molecules/PageHeading'
import { Users } from '../../../organisms/areas_users/Users'
import { Areas } from '../../../organisms/areas_users/Areas'
import Alert from '../../../molecules/Alert'
import InstitutionFilter from '../../../organisms/filters/InstitutionFilter'
import BranchOfficeFilter from '../../../organisms/filters/BranchOfficeFilter'
import useUserPermission from '../../../../hooks/useUserPermission'
import { getPageDescription, userActions } from '../../../../configs/usersConfig'
import useSearchParams from '../../../../hooks/useSearchParams'
import { TabContext, Tab, TabList, TabPanel } from '../../../atoms/tabs'
import useTab from '../../../../hooks/useTab'

export const AreasAndUsers = () => {
  const searchParams = useSearchParams()
  const generalPermissions = useUserPermission()
  const pageDescription = getPageDescription(generalPermissions)
  const { canFilterByInstitution, canFilterByBranchOffice } = useUserPermission('user')
  const [isBranchOfficeRequired, setIsBranchOfficeRequired] = useState(true)
  const { tabSelected, handleTabChange } = useTab('areaTab')

  const branchOfficeId = searchParams.get('branch_office_id')
  const isLoadingInstitution = !searchParams.get('institution_id')
  const isLoadingBranchOffice = isBranchOfficeRequired && !branchOfficeId

  const paramsLinkBranchOffice =
    branchOfficeId !== 'all_branches' && branchOfficeId !== 'to_be_assigned' ? `branch_office_id=${branchOfficeId}` : ''

  const userAction = searchParams.get('action')

  return (
    <div>
      {searchParams.has('action') && (
        <Alert type="success" className="mt-10" showClose>
          <span className="font-bold">{userActions[userAction].title}</span>
          <span className="font-normal">{userActions[userAction].body}</span>
        </Alert>
      )}
      <PageHeading
        title="Áreas y Usuarios"
        pageDescription={pageDescription}
        filterSection={
          <div className="flex-col md:flex-row flex w-full items-baseline md:gap-2 gap-4">
            {canFilterByInstitution && <InstitutionFilter />}
            {canFilterByBranchOffice && (
              <BranchOfficeFilter onChangeBranchRequired={setIsBranchOfficeRequired} hasWithOutOption />
            )}
          </div>
        }
      />
      {!((canFilterByInstitution && isLoadingInstitution) || (canFilterByBranchOffice && isLoadingBranchOffice)) && (
        <TabContext value={tabSelected}>
          <div className="mb-6 border-3">
            <div className="w-full bg-neutral-100">
              <TabList onChange={handleTabChange} className="bg-neutral-100 max-w-lg">
                <Tab label="Áreas" value="areaTab" style="blue" />
                <Tab label="Usuarios" value="userTab" style="blue" />
              </TabList>
            </div>
            <TabPanel value="areaTab">
              <Areas paramsLinkBranchOffice={paramsLinkBranchOffice} onUserSelect={handleTabChange} />
            </TabPanel>
            <TabPanel value="userTab">
              <Users paramsLinkBranchOffice={paramsLinkBranchOffice} />
            </TabPanel>
          </div>
        </TabContext>
      )}
    </div>
  )
}
