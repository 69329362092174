import React, { useEffect, useReducer } from 'react'
import axios from 'axios'
import useCrud from './useCrud'
import ReplyAll from '../components/atoms/icons/ReplyAll'
import Forward from '../components/atoms/icons/Forward'

const reducer = (state, action) => {
  switch (action.type) {
    case 'sending':
      return { ...state, webhook_status: 'sending', clientSending: true }
    case 'syncFromResend':
      return { ...state, ...action.answer, clientSending: false }
    case 'syncFromServer':
      if (!action?.answer) return
      return { ...state, ...action.answer }
  }
}

export const webhookStatusMap = {
  initial: 'No enviada',
  success: 'Enviada',
  send_with_error: 'Envío incompleto',
  failed: 'Envío fallido'
}

export const webhookStatusColorMap = {
  failed: 'text-red-700',
  initial: 'text-blue-700',
  success: 'text-green-700',
  send_with_error: 'text-yellow-700'
}

const apiText = {
  resend: 'Reenviar a la API',
  send: 'Enviar a API'
}

export const webhooksPropsMap = {
  send_with_error: {
    title: apiText.resend,
    icon: <ReplyAll className="transform -scale-x-100" />
  },
  failed: {
    title: apiText.resend,
    icon: <ReplyAll className="transform -scale-x-100" />
  },
  initial: {
    title: apiText.send,
    icon: <Forward className="transform" />
  }
}

export const errorResendInfo = {
  list: (
    <>
      La respuesta del formulario fue enviada a la API, pero el estado de la respuesta http indica un error. Reenvía la
      información a la API en la columna de <span className="font-bold">Acciones</span>
    </>
  ),
  detail: (
    <>
      La respuesta del formulario fue enviada a la API, pero el estado de la respuesta http indica un error. Reenvía la
      información con la opción <br />
      <span className="font-bold flex items-end gap-1">
        Reenviar a la API <ReplyAll className="transform -scale-x-100 text-xl" />.
      </span>
    </>
  )
}

export const hasResend = status => {
  return ['failed', 'initial', 'send_with_error'].includes(status)
}

export const useAnswerToWebhook = ({ answer: answerFromServer, formId }) => {
  const [answer, dispatch] = useReducer(reducer, answerFromServer)

  const urlBase = `/admin/v1/forms/${formId}/answers/${answer?.id}`

  const onResend = async evt => {
    evt.preventDefault()
    dispatch({ type: 'sending' })
    try {
      const { data } = await axios.post(`${urlBase}/resend_to_webhook.json`)
      dispatch({ type: 'syncFromResend', answer: data })
    } catch (error) {}
  }

  const { get: getAllAnswerApiLog } = useCrud(`${urlBase}/answer_api_logs`)

  useEffect(() => {
    if (!answer?.clientSending) {
      dispatch({ type: 'syncFromServer', answer: answerFromServer })
    }
  }, [answerFromServer])

  return { answer, onResend, getAllAnswerApiLog }
}
