import React from 'react'
import CheckboxField from '../../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../../molecules/FieldTitle'
import { transformDefaultQuestion } from '../../../../../configs/formConfigurationConfig'
import useFieldValue from '../../../../../hooks/useFieldValue'

const AleatoryOrder = () => {
  const stepsAttributesValue = useFieldValue('steps_attributes')
  const defaultQuestions = useFieldValue('default_questions')

  const handleChange = checked => {
    if (checked && defaultQuestions?.length > 1) {
      transformDefaultQuestion(stepsAttributesValue)
    }
  }

  return (
    <>
      <FieldTitle title="Orden de preguntas y respuestas" className="md:mb-3" />
      <CheckboxField
        name="aleatory_order"
        margin="none"
        label="Presentar preguntas y respuestas de forma aleatoria."
        onChange={handleChange}
      />
      <p className="ml-7 text-gray-500">
        Las preguntas y las opciones de respuesta se mostrarán en forma aleatoria para cada persona que complete el
        formulario. Si estableces grupos de preguntas, estas se mantendrán en el mismo bloque.
      </p>
    </>
  )
}

export default AleatoryOrder

AleatoryOrder.propTypes = {}
