const senderTypes = {
  public_entity: {
    name: 'Entidad pública',
    colorClasses: 'bg-blue-400 text-blue-700 border-blue-600 border rounded-sm',
    formTitle: 'feedback_do_you_have_a_suggestion_to_improve_facilita',
    urlCreate: '/admin/v1/feedbacks'
  },
  citizen: {
    name: 'Ciudadano',
    colorClasses: 'bg-green-500 text-green-800 border-green-700 border rounded-sm',
    formTitle: 'feedback_do_you_have_suggestions_to_improve_this_form',
    urlCreate: '/v1/feedbacks'
  },
  citizen_quick_search: {
    name: 'Consulta rápida',
    colorClasses: 'bg-green-500 text-green-700',
    formTitle: 'feedback_do_you_have_a_suggestion_to_improve_this_page',
    urlCreate: '/v1/feedbacks'
  }
}

const getPageHeadingData = ({
  canFilterByInstitution,
  canFilterByBranchOffice,
  canFilterAndHasNotBranchOffices,
  canSeeBranchRecords
}) => {
  const title = canFilterByInstitution ? 'Feedback' : 'Feedback de la ciudadanía'

  const pageDescription = (() => {
    if (canFilterByInstitution)
      return 'Selecciona la entidad para ver los comentarios de las personas usuarias de Facilita.'
    if (canFilterByBranchOffice) return 'Selecciona la sede cuyo feedback quieres ver.'
    if (canFilterAndHasNotBranchOffices)
      return 'Estos son los comentarios y sugerencias de los ciudadanos para tu institución.'
    if (canSeeBranchRecords) return 'Estos son los comentarios y sugerencias de los ciudadanos para tu sede.'
    return 'Estos son los comentarios y sugerencias de los ciudadanos para los servicios que tienes asignados.'
  })()

  return { title, pageDescription }
}

export { senderTypes, getPageHeadingData }
