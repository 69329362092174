import React from 'react'
import SendOk from '../../../images/send_ok.svg'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import { numberFormatter } from '../../../utils/helpers'
import axios from 'axios'
import { useQuery } from 'react-query'
import { Button } from '../../atoms/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Feedback from '../feedbacks/Feedback'

const confirmAnswer = answerCode =>
  axios.put(`/v1/answers/${answerCode}/confirm.json`, { sisoli: 'sisoli' }).then(res => res.data)

export const FormServerResponse = ({ answerCode, formType }) => {
  const { t } = useTranslation('form_registered')
  const { data: answer, status } = useQuery(['confirm_answer', answerCode], () => confirmAnswer(answerCode), {
    refetchOnWindowFocus: false
  })

  let successfulMessage = t('form_registered_your_request_has_been_successfully_sent')
  if (answer?.procedure) successfulMessage = t('form_registered_your_request_has_been_successfully_registered')
  if (formType === 'assessment') successfulMessage = t('form_registered_your_assessment_has_been_successfully_sent')

  if (status !== 'success') return null
  return (
    <>
      <div className="flex flex-col md:max-w-3xl justify-center text-center m-auto">
        <img src={SendOk} alt="icono check" className="mb-6 max-w-sm m-auto" />
        <h1 className="font-bold leading-none text-2xl md:text-3xl text-green-700 mb-4 w-full">{successfulMessage}</h1>
        <div className="flex flex-col leading-tight my-6">
          <div className="bg-neutral-100 px-6 md:px-10 font-bold">
            <div className="flex justify-between py-6">
              <span className="text-left">{t('form_registered_request_code')}</span>
              <span className="ml-8 text-right">{answer.hashid}</span>
            </div>
            {answer.procedure && answer.procedure.citizen_fullname && (
              <div className="flex justify-between py-6 border-t border-gray-300">
                <span className="text-left">{t('form_registered_applicant_name')}</span>
                <span className="ml-8 text-right">{answer.procedure.citizen_fullname}</span>
              </div>
            )}
            <div className="flex justify-between py-6 border-t border-gray-300">
              <span className="text-left">{t('form_registered_submission_date')}</span>
              <time className="ml-8 text-right" dateTime={answer.created_at}>
                {answer.created_at_format}
              </time>
            </div>
            {answer.payment && (
              <>
                <div className="flex justify-between py-6 border-t border-gray-300">
                  <span className="text-left">{t('form_registered_payment_method')}</span>
                  <span className="ml-8 text-right">{paymentMethods[answer.selected_kind_payment].label}</span>
                </div>
                <div className="flex justify-between py-6 border-t border-gray-300">
                  <span className="text-left">{t('form_registered_payment_code')}</span>
                  <span className="ml-8 text-right">{answer.payment.transaction_number}</span>
                </div>
                <div className="flex justify-between py-6 border-t border-gray-300">
                  <span className="text-left">{t('form_registered_amount_paid')}</span>
                  <span className="ml-8 text-right">{numberFormatter.currency(answer.payment.cost)}</span>
                </div>
              </>
            )}
            {formType === 'assessment' && answer.show_score && (
              <>
                <div className="flex justify-between py-6 border-t border-gray-300">
                  <span className="text-left">{t('score')}</span>
                  <span className="ml-8 text-right">{answer.score}</span>
                </div>
                <div className="flex justify-center py-6 border-t border-gray-300">
                  <Button
                    el="a"
                    target="_blank"
                    href={`/v1/answers/${answer.hashid}.pdf`}
                    variant="secondary"
                    className="md:max-w-56 h-14 font-bold"
                    size="full"
                  >
                    Revisa tu resultado
                  </Button>
                </div>
              </>
            )}
          </div>
          {answer.tracking_url && (
            <div className="bg-neutral-300 py-6 px-10 font-bold">
              {answer.procedure
                ? t('form_registered_you_can_track_it_by_entering_your_request_code_at')
                : t('form_registered_you_can_track_your_request_using_this_link')}
              <div className="text-base md:text-lg mt-2">
                <a href={answer.tracking_url}>{answer.tracking_url}</a>
              </div>
            </div>
          )}
        </div>

        {answer.procedure?.auto_response_text ? (
          <div
            className="hint-html-injected mb-8 bg-neutral-100 p-4 md:p-8 border-neutral-200 border-2 text-left"
            dangerouslySetInnerHTML={{ __html: answer.procedure.auto_response_text }}
          />
        ) : null}

        {answer.auto_response && <p> {t('form_registered_we_have_sent_a_copy_of_this_certificate_to_your_email')}</p>}

        {answer.procedure ? (
          <div className="w-full mt-8 text-center">
            <Button
              el="a"
              target="_blank"
              href={answer.procedure.shipment_constancy_url}
              variant="secondary"
              className="md:max-w-56 h-14 font-bold"
              size="full"
              endIcon={<GetAppIcon />}
            >
              {t('form_registered_download_pdf')}
            </Button>
          </div>
        ) : null}
      </div>
      <Feedback senderType="citizen" />
    </>
  )
}

FormServerResponse.propTypes = {
  answerCode: PropTypes.string,
  formType: PropTypes.string
}
