import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import Tooltip from '../../atoms/Tooltip'

const DownloadPDFButton = ({ formId, answerId, full = false, className }) => {
  return (
    <a
      download
      target="_blank"
      href={`/admin/v1/forms/${formId}/answers/${answerId}.pdf`}
      className={className}
      rel="noreferrer"
      aria-label="Descargar PDF"
    >
      <Tooltip title="Descargar PDF">
        {full ? (
          <Button variant="text" component="div" role="generic" className="text-blue-700" endIcon={<GetAppIcon />}>
            Descargar
          </Button>
        ) : (
          <IconButton className="text-blue-700" component="div" role="generic">
            <GetAppIcon />
          </IconButton>
        )}
      </Tooltip>
    </a>
  )
}

export default DownloadPDFButton

DownloadPDFButton.propTypes = {
  answerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full: PropTypes.bool
}
