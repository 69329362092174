import React from 'react'
import useInstitution from '../../../hooks/useInstitution'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link, useLocation } from 'react-router-dom'
import { useBranchOfficeFilter } from '../../../hooks/useBranchOfficeFilter'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'

export const AreasTable = ({ internalAreas = [], onDestroy, onUserSelect }) => {
  const { querySameInstitution } = useInstitution()
  const showBranchOfficeColumn = useBranchOfficeFilter(['all_branches'])
  const location = useLocation()
  const path = areaId => queryString.stringify({ ...queryString.parse(location.search), area_id: areaId })

  const showActions = area => {
    return (
      <td className="w-1/5 text-right pr-2">
        <EditButton to={`/areas/${area.id}/editar`} />
        <DeleteButton onClick={() => onDestroy(area)} />
      </td>
    )
  }
  const handleChangeToUser = id => {
    onUserSelect(id)
  }

  return (
    <div className="pt-8 pb-2">
      <Table
        spacing="normal"
        className="table-fixed w-full"
        values={internalAreas}
        head={
          <Tr body={false}>
            <Th className="w-2/5 text-left">Nombre</Th>
            {showBranchOfficeColumn && <Th className="w-1/5">Sedes</Th>}
            <Th className="w-1/5 text-left">N.º usuarios</Th>
            {querySameInstitution && (
              <Th className="w-1/5 text-right">
                <span className="pr-7 md:pr-5">Acciones</span>
              </Th>
            )}
          </Tr>
        }
        body={internalAreas.map(area => (
          <Tr key={area.id}>
            <Td className="text-left text-lg">{area.name}</Td>
            {showBranchOfficeColumn && <Td className="text-lg">{area.branch_office_name}</Td>}
            <Td className="text-lg">
              <Link to={`/areas-y-usuarios?${path(area.id)}`} onClick={() => handleChangeToUser(area.id)}>
                {area.users_count}
              </Link>
            </Td>
            {querySameInstitution && showActions(area)}
          </Tr>
        ))}
      />
    </div>
  )
}

AreasTable.propTypes = {
  internalAreas: PropTypes.array,
  onDestroy: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func
}
