import React from 'react'
import PropTypes from 'prop-types'
import ConfigurationContainer from '../../ConfigurationContainer'
import { getVisibleConfigItems } from '../../../../../configs/formTypeConfig'
import Instructions from '../../../../molecules/Instructions'
import { getInstructions } from '../../../../../configs/formsConfig'
import PrivacyPolicyCreator from '../../PrivacyPolicyCreator'
import StepsFields from '../../StepsFields'
import UpdateVersionField from '../../UpdateVersionField'

const QuestionsConfiguration = ({ config }) => {
  const configItems = [
    {
      visible: true,
      component: <Instructions list={getInstructions(config.from_template)} />
    },
    {
      visible: true,
      component: (
        <StepsFields
          addQuestions={config.add_questions}
          addPages={config.add_questions}
          sortQuestions={config.sort_questions}
          editDeleteQuestions={config.edit_delete_questions}
        />
      )
    },
    {
      visible: true,
      component: <PrivacyPolicyCreator exampleFileUrl={config.privacy_policy?.url} required={false} />,
      withoutSeparator: true
    },
    {
      visible: true,
      component: <UpdateVersionField />
    }
  ]

  const visibleConfigItems = getVisibleConfigItems(configItems)

  return (
    <ConfigurationContainer configItems={visibleConfigItems} title="Preguntas del formulario" bottomSpace={false} />
  )
}

export default QuestionsConfiguration

QuestionsConfiguration.propTypes = {
  config: PropTypes.object
}
