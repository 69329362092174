import PropTypes from 'prop-types'
import React from 'react'
import AnswerRow from './AnswerRow'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'

const AnswersTable = ({ answers = [], form, formHasCost }) => {
  return (
    <div className="pb-8 pt-2">
      <Table
        className="table-auto w-full"
        values={answers}
        head={
          <Tr body={false}>
            <Th>Detalle de respuesta</Th>
            <Th>Fecha de respuesta</Th>
            {formHasCost && <Th>Medio de Pago/Estado</Th>}
            {form.subtype === 'assessment' && <Th>Puntaje</Th>}
            {form.has_webhook && <Th>API</Th>}
            <Th>Estado</Th>
            <Th className="pl-6 w-48">Acciones</Th>
            <Th className="w-10" />
          </Tr>
        }
        body={answers.map(answer => (
          <AnswerRow key={answer.id} answer={answer} form={form} formHasCost={formHasCost} />
        ))}
      />
    </div>
  )
}

export default AnswersTable

AnswersTable.propTypes = {
  answers: PropTypes.array,
  form: PropTypes.object,
  formHasCost: PropTypes.bool
}
