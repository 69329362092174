import { useAuth } from '../../components/molecules/Auth'
import useHasRolePermissions from '../useHasRolePermissions'
import { roles, types } from '../../configs/permissionsConfig'

const { admin, coordinator, branch_coordinator: branchCoordinator, collaborator } = roles
const { form: formType } = types

const Form = () => {
  const { user } = useAuth()
  const hasRolePermissions = useHasRolePermissions()

  const canCreate = hasRolePermissions([
    admin,
    coordinator,
    branchCoordinator,
    [collaborator, formType, 'create_or_edit']
  ])
  const canCreateOrEdit = canCreate

  const isAnAllowedForm = form => user.allowed_forms?.includes(form?.id)
  const canEdit = form =>
    hasRolePermissions([
      admin,
      coordinator,
      branchCoordinator,
      { rolePermission: [collaborator, formType, 'create_or_edit'], additionalCondition: isAnAllowedForm(form) }
    ])
  const canDelete = form =>
    hasRolePermissions([
      admin,
      coordinator,
      branchCoordinator,
      {
        rolePermission: [collaborator, formType, 'create_or_edit'],
        additionalCondition: isAnAllowedForm(form) && form?.created_by === user.id
      }
    ])

  const showStartMessage =
    hasRolePermissions([coordinator, branchCoordinator, [collaborator, formType, 'create_or_edit']]) &&
    localStorage.getItem('showFormStartMessage') === 'true'
  const canSeeAnswer = form => hasRolePermissions([admin]) && form.institution_id !== user.institution_id

  const canSeeAnswerApiDetail = hasRolePermissions([admin, coordinator, branchCoordinator])

  const canSeeExport = hasRolePermissions([admin])

  return {
    canCreate,
    canEdit,
    canDelete,
    showStartMessage,
    canSeeAnswer,
    canSeeAnswerApiDetail,
    canCreateOrEdit,
    canSeeExport
  }
}

export default Form
