import React from 'react'
import useArea from '../../../hooks/useArea'
import useSearchParams from '../../../hooks/useSearchParams'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import { dataToOptions } from '../../../utils/helpers'
import { isSuccessful } from '../../../hooks/useQuery'

const AreasFilter = () => {
  const searchParams = useSearchParams()
  const { useGetAllAreas } = useArea()
  const { data: areas, status } = useGetAllAreas(
    searchParams.get('branch_office_id'),
    searchParams.get('institution_id')
  )
  const defaultOption = {
    label: 'Seleccionar área',
    value: '',
    className: 'border-b-2 border-gray-600 pb-2 w-full'
  }

  if (!isSuccessful(status)) return null

  return (
    <AutocompleteField
      label="Área"
      options={areas ? [defaultOption, ...dataToOptions(areas)] : []}
      name="area_id"
      size="full"
      margin="none"
      boxClassName="min-w-60 mt-2"
      renderOption={option => <span className={option.className}>{option.label}</span>}
    />
  )
}

export default AreasFilter

AreasFilter.propTypes = {}
