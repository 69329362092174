import React from 'react'
import PropTypes from 'prop-types'
import ConfigurationContainer from '../../ConfigurationContainer'
import { getVisibleConfigItems } from '../../../../../configs/formTypeConfig'
import { fieldTitle } from '../../../../../configs/templatesConfig'
import AutoResponse from '../../config_items/assessment/AutoResponse'
import SubscribedEmail from '../../config_items/assessment/SubscribedEmail'
import AutoScore from '../../config_items/assessment/AutoScore'
import AleatoryOrder from '../../config_items/assessment/AleatoryOrder'
import FieldTitle from '../../../../molecules/FieldTitle'
import DefaultQuestions from '../../config_items/assessment/DefaultQuestions'
import PublishSchedule from '../../config_items/PublishSchedule'
import UniqueResponsePerDocument from '../../config_items/UniqueResponsePerDocument'

const Configuration = ({ config }) => {
  const configItems = [
    {
      visible: true,
      component: <DefaultQuestions />
    },
    {
      visible: true,
      component: <AleatoryOrder />
    },
    {
      visible: true,
      component: <FieldTitle title="Gestión de respuestas" className="md:mb-3" />,
      withoutSeparator: true
    },
    {
      visible: config.answers_by_email,
      component: <SubscribedEmail />,
      withoutSeparator: true
    },
    {
      visible: fieldTitle('auto_response', config.auto_response),
      component: <AutoResponse config={config} />,
      withoutSeparator: true
    },
    {
      visible: true,
      component: <AutoScore />
    },
    {
      visible: true,
      component: <UniqueResponsePerDocument />
    },
    {
      visible: true,
      component: <PublishSchedule name="subscribed_email" />
    }
  ]

  const visibleConfigItems = getVisibleConfigItems(configItems)

  return <ConfigurationContainer configItems={visibleConfigItems} title="Configuración" />
}

export default Configuration

Configuration.propTypes = {
  config: PropTypes.object
}
